#root {
    font-family: 'Spoqa Han Sans', sans-serif;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
a {
    text-decoration: none;
    color: inherit;
}

@media (min-width: 1920px) {
    #root,
    html,
    body {
        font-size: 16px;
    }
}
@media (max-width: 1919px) {
    #root,
    html,
    body {
        font-size: 14px;
    }
}
@media (max-width: 767px) {
    #root,
    html,
    body {
        font-size: 12px;
    }
}
