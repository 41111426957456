@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: normal;
  src: url("../static/fonts/SpoqaHanSansNeo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Spoqa Han Sans";
  font-weight: bold;
  src: url("../static/fonts/SpoqaHanSansNeo-Bold.ttf") format("truetype");
}
